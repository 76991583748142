@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;1,100;1,200;1,300&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.hero-container {
    background: url(/static/media/supertomi.ec2726f2.jpeg) center center/cover
    no-repeat;
    height: 140vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: rgb(255, 255, 255);
    font-size: 100px;
    border-radius: 10px;
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    -webkit-filter: none;
            filter: none;
}

.hero-container > p {
    color: #fff;
    font-size: 25px;
    border-radius: 10px;
    background-color: rgba(99, 99, 99, 0.15);
    font-weight: bold;
    text-align: center;
    margin-top: -50px;
    margin-left: 20px;
    margin-right: 20px;
}

.hero-container > p1 {
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: underline;
}

.hero-container > row {
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
}
