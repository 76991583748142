
.hero-container {
    background: url('/src/assets/supertomi.jpeg') center center/cover
    no-repeat;
    height: 140vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
}

.hero-container > h1 {
    color: rgb(255, 255, 255);
    font-size: 100px;
    border-radius: 10px;
    margin-top: 100px;
    margin-left: 20px;
    margin-right: 20px;
    filter: none;
}

.hero-container > p {
    color: #fff;
    font-size: 25px;
    border-radius: 10px;
    background-color: rgba(99, 99, 99, 0.15);
    font-weight: bold;
    text-align: center;
    margin-top: -50px;
    margin-left: 20px;
    margin-right: 20px;
}

.hero-container > p1 {
    color: #fff;
    font-size: 12px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    text-decoration: underline;
}

.hero-container > row {
    margin-top: 10px;
    flex-direction: row;
    justify-content: space-between;
}